<template>
  <div class="connect-payment-service">
    <div class="connect">
      <div class="inner">
        <div class="title">Connect to any payment service</div>
        <div class="content margin-top-15">
          <div>Today’s standard payments strategy is to leverage the right mix of services to support your business goals. But building and maintaining these connections yourself is costly and complicated.</div>
          <div class="margin-top-20">moozumi provides the rich functionality of our orchestration platform plus the power to connect to virtually any payment service — all through a single API connection. That lets you get to market faster, return higher payment success rates, and lower your business risk.</div>
        </div>
      </div>
    </div>
    <div class="service">
      <div class="inner">
        <div class="left-wrapper">
          <div class="title">
            <div>Connect to 120+ Payment Service Providerss</div>
          </div>
          <div class="content margin-top-15">
            <div>You need the flexibility to connect to the right payment service. Whether that’s to enter new geographies faster, support your customers and partners better, or improve authorization rates for your transactions. After all, no single gateway is perfect for every situation.</div>
            <div class="margin-top-20">moozumi offers the world’s deepest ecosystem of payment service providers and gateways. You can leverage our pre-built connections to over 120 services to transact the way your business demands. All through a single API connection to minimize development cost while maximizing flexibility.</div>
          </div>
        </div>
        <img src="../../assets/images/connect_payment_service/p1.png" />
      </div>
    </div>
    <div class="fraud">
      <div class="inner">
        <img src="../../assets/images/connect_payment_service/p2.png" />
        <div class="right-wrapper">
          <div class="title">
            <div>Reduce Fraud and False Declines</div>
          </div>
          <div class="content margin-top-15">
            <div>Your customers are around the world and across multiple markets. Relying on a single fraud vendor to support these different customer types may drive significantly higher than expected false declines. </div>
            <div class="margin-top-20">moozumi enables customers to leverage the ideal mix of fraud services to support their payment strategy. Connect to and leverage your preferred fraud tools with ease – reducing the number of direct integrations you need. That drives higher authorization rates, more revenue, and the best, most secure customer experience.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="virtually">
      <div class="inner">
        <div class="left-wrapper">
          <div class="title">
            <div>Connect to Virtually Any Payment Service</div>
          </div>
          <div class="content margin-top-15">
            <div>Digital businesses often need to connect to a range of PCI-compliant end-points. That might be to connect to a partner merchant like a hotel and car rental company, for example. Or it could be to an emerging gateway that isn’t currently connected in our deep ecosystem.</div>
            <div class="margin-top-20">moozumi’s universal platform makes it easy to build out connections to additional PCI-compliant services. Our customers use our toolkit to efficiently transact with fraud services, third-party merchants, and additional gateways – all via our single API integration.</div>
          </div>
        </div>
        <img src="../../assets/images/connect_payment_service/p3.png" />
      </div>
    </div>
    <ConnectwiththeexpertsinPaymentOrchestration></ConnectwiththeexpertsinPaymentOrchestration>
  </div>
</template>
<script>
import ConnectwiththeexpertsinPaymentOrchestration from '../../components/ConnectwiththeexpertsinPaymentOrchestration.vue'

export default {
  components: {
    ConnectwiththeexpertsinPaymentOrchestration
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/variables.scss';
.connect {
  padding: 150px 0 230px;
  background: url(../../assets/images/connect_payment_service/banner.png);
  background-size: cover;
  .inner {
    width: 870px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
    .title {
      font-family: Helvetica-Bold;
      font-size: 52px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 68px;
    }
    .content {
      font-family: HelveticaNeue;
      font-size: 18px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 36px;
    }
  }
}
.connect-payment-service {
  .service, .fraud, .virtually {
    padding-top: 120px;
    .inner {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .left-wrapper, .right-wrapper {
        width: 655px;
        max-width: 100%;
        .title {
          font-family: HelveticaNeue-Bold;
          font-size: 36px;
          color: #00277E;
          letter-spacing: 0;
          text-align: left;
          line-height: 52px;
          &:before {
            content: '';
            width: 140px;
            height: 6px;
            display: inline-block;
            background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
            border-radius: 5px;
            margin-bottom: 15px;
          }
        }
        .content {
          font-family: HelveticaNeue;
          font-size: 20px;
          color: #0C399C;
          letter-spacing: 0;
          text-align: left;
          line-height: 36px;
        }
      }
    }
    img {
      width: 462px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
.virtually {
  padding-bottom: 120px;
}
@media screen and (max-width: 1200px) {
  .connect-payment-service {
    .service, .fraud, .virtually {
      .left-wrapper, .right-wrapper {
        padding: 0 15px;
      }
      img {
        padding: 0 15px;
        margin-top: 20px;
      }
    }
  }
}
</style>
